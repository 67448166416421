<template>
  <div class="home">
    <div class="nav__wrapper">
      <nav class="nav row">
        <div class="nav__logo">
          <img
            @click="$router.push('/')"
            class="main__logo"
            src="../assets/images/landing-logo.svg"
            alt="OneBank Logo"
          />
        </div>
        <ul class="nav__left">
          <li class="nav__link">
            <a
              class="nav__item"
              href="https://freemoneydocs.z6.web.core.windows.net/"
              target="_blank"
              rel="noopener"
              >API Documentation</a
            >
          </li>
        </ul>
        <ul class="nav__right">
          <li class="nav__link pending" @click="$router.push('')">
            <a class="nav__item nav__btn nav__btn--white">Sign In</a>
          </li>
          <li class="nav__link pending" @click="$router.push('')">
            <a class="nav__item nav__btn nav__btn--red">Register</a>
          </li>
        </ul>

        <img
          class="nav__toggle--open"
          ref="openBtn"
          src="../assets/images/toggle.svg"
          alt="toggle-icon"
        />

        <div class="nav-mobile" ref="mobileMenu">
          <div class="nav-mobile__heading">
            <img
              class="nav-mobile__logo"
              @click="$router.push('/')"
              src="../assets/images/landing-logo.svg"
              alt="OneBank Logo"
            />
            <img
              class="close-icon"
              ref="closeBtn"
              src="../assets/images/toggle-close.svg"
              alt="toggle-clsoe-icon"
            />
          </div>
          <ul class="nav-mobile__menu" ref="mobileLinks">
            <li class="nav__link">
              <a
                class="nav__item"
                href="https://freemoneydocs.z6.web.core.windows.net/"
                target="_blank"
                rel="noopener"
                >API Documentation</a
              >
            </li>
            <li class="nav__link pending" @click="$router.push('')">
              <a class="nav__item nav__btn nav__btn--white">Sign In</a>
            </li>
            <li class="nav__link pending" @click="$router.push('')">
              <a class="nav__item nav__btn nav__btn--red">Register</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>

    <section class="hero">
      <div class="hero__wrapper row">
        <div class="hero__left">
          <h3 class="hero__heading-tertiary">
            Integrate your business with OneBank and build payment solutions
          </h3>

          <ul class="hero__btns">
            <li class="nav__link pending" @click="$router.push('')">
              <a class="nav__item nav__btn nav__btn--red">Register</a>
            </li>
          </ul>
        </div>
        <div class="hero__right">
          <img
            class="img-two"
            src="../assets/images/landing-dev-image.svg"
            alt="landing-dev-image"
          />
        </div>
      </div>
    </section>

    <section id="features" class="features">
      <div class="features__cards row">
        <div class="card">
          <img
            class="card__img"
            src="../assets/images/landing-self-icon.svg"
            alt="landing-self-icon"
          />
          <h4>Track your daily activities</h4>
        </div>
        <div class="card">
          <img
            class="card__img"
            src="../assets/images/landing-airtime-data-icon.svg"
            alt="/landing-airtime-data-icon"
          />
          <h4>Create and Manage all accounts</h4>
        </div>

        <div class="card">
          <img
            class="card__img"
            src="../assets/images/landing-bills-icon.svg"
            alt="/landing-bills-icon"
          />
          <h4>Run and Test your APIs</h4>
        </div>
      </div>
    </section>

    <app-footer
      @openPrivacyModal="showPrivacyModal = !showPrivacyModal"
    ></app-footer>

    <app-privacy-policy
      v-if="showPrivacyModal"
      :showPrivacyModal="showPrivacyModal"
      @close="showPrivacyModal = false"
    ></app-privacy-policy>
  </div>
</template>

<script>
import PrivacyPolicy from "@/widgets/PrivacyPolicy.vue";
import AppFooter from "@/views/Footer.vue";
export default {
  components: {
    "app-privacy-policy": PrivacyPolicy,
    "app-footer": AppFooter
  },
  data() {
    return {
      showPrivacyModal: false
    };
  },
  mounted() {
    const openBtn = this.$refs.openBtn;
    const closeBtn = this.$refs.closeBtn;
    const mobileLinks = Array.from(this.$refs.mobileLinks.childNodes);

    closeBtn.addEventListener(
      "click",
      () => (this.$refs.mobileMenu.style.display = "none")
    );

    openBtn.addEventListener(
      "click",
      () => (this.$refs.mobileMenu.style.display = "initial")
    );

    mobileLinks.forEach(menu => {
      menu.addEventListener(
        "click",
        () => (this.$refs.mobileMenu.style.display = "none")
      );
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_home";
</style>
